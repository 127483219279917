import React, { useState, useEffect, useRef } from 'react';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { instructions as defaultInstructions } from '../utils/conversation_config.js';
import { Button } from '../components/button/Button';
import { WavRenderer } from '../utils/wav_renderer';
import './ConsolePage.scss';

const LOCAL_RELAY_SERVER_URL: string = process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';

const fetchFromGoogleSheets = async (id: string): Promise<{
  instructions: string;
  logoUrl: string | null;
  sellerEmail: string | null;
  orgId: string | null;
  questions: string[];
}> => {
  const url = `https://script.google.com/macros/s/AKfycbz54rQjMTP-g-vFXVHlIXz_Ysz8mcJkYDtVNzRq1Pgd0svk22UCrRuPCKE31EUJwccF/exec?ID=${encodeURIComponent(id)}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.status}`);
    }
    const data = await response.json();
    if (data.error) {
      throw new Error(data.error); // Handle error messages from the server
    }
    return {
      instructions: data.Instructions || '',
      logoUrl: data.SellerLogo || null,
      sellerEmail: data.SellerEmail || null,
      orgId: data.Org_ID || null,
      questions: data.Questions ? data.Questions.split(',').map((q: string) => q.trim()) : [],
    };
  } catch (error) {
    console.error('Error fetching from Google Sheets:', error);
    throw new Error('Failed to fetch instructions');
  }
};

const Logo: React.FC<{ logoUrl: string | null }> = ({ logoUrl }) => {
  if (logoUrl) {
    return (
      <img
        src={logoUrl}
        alt="Seller Logo"
        style={{
          maxWidth: '150px',
          maxHeight: '150px',
          width: 'auto',
          height: 'auto',
        }}
      />
    );
  }
  return (
    <div
      style={{
        fontSize: '150px', // Adjust size to fit the logo dimensions
        lineHeight: '150px',
        maxWidth: '150px',
        maxHeight: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
      aria-label="Default Logo"
    >
      ☀️
    </div>
  );
};

const ConversationAnimation: React.FC<{
  clientCanvasRef: React.RefObject<HTMLCanvasElement>;
  serverCanvasRef: React.RefObject<HTMLCanvasElement>;
}> = ({ clientCanvasRef, serverCanvasRef }) => (
  <div style={{
    width: '150px',
    height: '64px',
    display: 'flex',
    margin: '16px 0',
  }}>
    <canvas ref={clientCanvasRef} style={{ width: '50%', height: '100%' }} />
    <canvas ref={serverCanvasRef} style={{ width: '50%', height: '100%' }} />
  </div>
);

const AgreementsDisplay: React.FC<{ agreements: string[] }> = React.memo(({ agreements }) => {
  console.log('AgreementsDisplay rendered'); // For debugging render behavior

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '500px',
        margin: '16px auto',
        backgroundColor: '#FFF',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>Agreements</h3>
      {agreements.length === 0 ? (
        <p style={{ textAlign: 'center', color: '#999' }}>No actionable agreements found.</p>
      ) : (
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {agreements.map((agreement, index) => (
            <li
              key={index}
              style={{
                backgroundColor: '#F3F4F6',
                padding: '8px',
                marginBottom: '8px',
                borderRadius: '4px',
              }}
              dangerouslySetInnerHTML={{ __html: agreement }} // Render HTML content
            />
          ))}
        </ul>
      )}
    </div>
  );
});

export const ConsolePage: React.FC = () => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [currentInstructions, setCurrentInstructions] = useState<string>(defaultInstructions);
  const [conversationItems, setConversationItems] = useState<ItemType[]>([]);
  const [summary, setSummary] = useState<string>('');

  const wavRecorderRef = useRef<WavRecorder>(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(new WavStreamPlayer({ sampleRate: 24000 }));
  const clientRef = useRef<RealtimeClient | null>(null);

  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);

  const startTimeRef = useRef<string>(new Date().toISOString());
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Ref to hold the timeout ID

  const [email, setEmail] = useState<string>(''); // Stores email input
  const [isEmailSending, setIsEmailSending] = useState(false); // Indicates if email submission is in progress
  const [isTranscriptSaving, setIsTranscriptSaving] = useState<boolean>(false); // Indicates if transcript is being saved

  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [agreements, setAgreements] = useState<string[]>([]);

  const [sellerEmail, setSellerEmail] = useState<string | null>(null);

  const [orgId, setOrgId] = useState<string>('default-org-id');
  const [welcomeMessage, setWelcomeMessage] = useState<string>("Hello!"); // Default to "Hello!"
  const [dynamicQuestions, setDynamicQuestions] = useState<string[]>([]); // Empty initially

useEffect(() => {
  const params = new URLSearchParams(window.location.search);
  const instructionsId = params.get('instructions') || 'default-id'; // Default to 'default-id'

  fetchInstructions(instructionsId); // Always fetch from Google Sheets
}, []);

const fetchInstructions = async (id: string) => {
  try {
    setIsLoading(true);

    if (id === 'default-id') {
      setCurrentInstructions(defaultInstructions);
      setLogoUrl(null);
      setSellerEmail(null);

      // Fallback to default "Hello!" message
      setDynamicQuestions(["Hello!"]);
      setWelcomeMessage("Hello!");
      initializeClient(defaultInstructions);
      return;
    }

    const { instructions, logoUrl, sellerEmail: fetchedEmail, orgId, questions } = await fetchFromGoogleSheets(id);
    setCurrentInstructions(instructions);
    setLogoUrl(logoUrl);
    setSellerEmail(fetchedEmail);
    setOrgId(orgId || 'default-org-id');

    if (questions.length > 0) {
      // Use the first fetched question as the welcomeMessage
      setWelcomeMessage(questions[0]);
      setDynamicQuestions(questions); // Populate the dynamicQuestions list
    } else {
      // Fallback to default "Hello!" message
      setDynamicQuestions(["Hello!"]);
      setWelcomeMessage("Hello!");
    }

    initializeClient(instructions);
  } catch (error) {
    console.error('Failed to fetch instructions:', error);
    setError('Failed to load instructions. Using default configuration.');
    setCurrentInstructions(defaultInstructions);
    setLogoUrl(null);
    setSellerEmail(null);

    // Fallback to default "Hello!" message
    setDynamicQuestions(["Hello!"]);
    setWelcomeMessage("Hello!");
    initializeClient(defaultInstructions);
  } finally {
    setIsLoading(false);
  }
};

const initializeClient = (instructions: string) => {
  console.log('Initializing client with instructions:', instructions);

  console.log('Initializing client without API key:', instructions);

  clientRef.current = new RealtimeClient(
    LOCAL_RELAY_SERVER_URL
      ? { url: LOCAL_RELAY_SERVER_URL }
      : {}
  );

  if (clientRef.current) {
    console.log('RealtimeClient initialized successfully');
    const client = clientRef.current;

    // Handle conversation interruption
    client.on('conversation.interrupted', async () => {
      console.log('Conversation interrupted');
      const trackSampleOffset = await wavStreamPlayerRef.current.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
        console.log('Interrupted response canceled:', { trackId, offset });
      }
    });

    // Handle conversation updates
    client.on('conversation.updated', async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayerRef.current.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      setConversationItems(items);
      console.log('Conversation updated:', items);
    });

    setError(null);
  } else {
    console.error('RealtimeClient initialization failed');
  }
};

  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#0099ff',
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              '#009900',
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  const toggleConnection = async () => {
    setIsLoading(true);
    if (!clientRef.current) {
      setError('Client not initialized. Please try again later.');
      setIsLoading(false);
      return;
    }

    if (isConnected) {
      await disconnectConversation();
    } else {
      await connectConversation();
    }
    setIsLoading(false);
  };

const connectConversation = async () => {
  const client = clientRef.current;
  const wavRecorder = wavRecorderRef.current;
  const wavStreamPlayer = wavStreamPlayerRef.current;

  if (!client) {
    setError('Client not initialized. Please try again later.');
    console.error('Client is null. Initialization failed.');
    return;
  }

  try {
    console.log('Starting connection process...');

    // Interrupt playback and cancel ongoing AI response if applicable
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    console.log('Playback interrupted:', trackSampleOffset);

    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      console.log('Canceling response with trackId:', trackId, 'and offset:', offset);
      await client.cancelResponse(trackId, offset);
    }

    // Set up initial state variables
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);

    // Connect to microphone
    console.log('Starting microphone recording...');
    await wavRecorder.begin();

    // Connect the audio output
    console.log('Connecting audio output...');
    await wavStreamPlayer.connect();

    // Connect to the AI client
    console.log('Connecting to the AI client...');
    await client.connect();

    // Send initial "Hello!" message to the AI
    console.log('Sending dynamic welcome message...');
    client.sendUserMessageContent([{ type: 'input_text', text: welcomeMessage }]);

    // Initialize session settings
    console.log('Updating session settings...');
    client.updateSession({
      instructions: currentInstructions, // Pass the instructions
      input_audio_transcription: { model: 'whisper-1' },
      turn_detection: { type: 'server_vad' }, // Enable turn detection
    });

    // Start feeding audio data to the client
    console.log('Feeding audio data...');
    await wavRecorder.record((data) => {
      if (client.isConnected()) {
        client.appendInputAudio(data.mono);
      }
    });

    timeoutRef.current = setTimeout(async () => {
      console.log('Disconnecting due to timeout...');
      await disconnectConversation(); // Directly calls the disconnect logic
    }, 15 * 60 * 1000); // 15 minutes in milliseconds

    console.log('Connection successful.');
    setIsConnected(true);
    setError(null);
  } catch (error) {
    console.error('Error during connection:', error);
    setIsConnected(false);
    setError('Failed to connect to the AI. Please try again.');
  }
};
  
const saveTranscriptToGoogleSheet = async (sellerEmail: string | null = null) => {
  const url = 'https://script.google.com/macros/s/AKfycbweTjgDWzTRXs_eDjlJAuiZ60EOYBqSv3CNeBAejS36TmBp-kWJdWSbOE3nFYU4D9dP/exec';
  const transcript = conversationItems
    .map((item) => `${item.role}: ${item.formatted.transcript || '(No transcription available)'}`)
    .join('\n');

  const params = new URLSearchParams(window.location.search);
  const instructionsId = params.get('instructions') || 'default-id';

  const payload = {
    transcription: transcript,
    instructionsId: instructionsId,
    date: new Date().toISOString(),
    sellerEmail: sellerEmail,
    orgId: orgId, // Include Org_ID
    startQuestion: welcomeMessage, // Include the StartQuestion
  };

  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(payload));

    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    const result = await response.text(); // Get response as text first
    const data = JSON.parse(result);

    if (data.status !== 'Success') {
      throw new Error(`Server Error: ${data.message || 'Unknown error'}`);
    }

    console.log('Transcript saved:', data);
  } catch (error) {
    console.error('Error saving transcript:', error);
  }
};

const disconnectConversation = async () => {
  const client = clientRef.current;
  if (!client) return;

  const wavRecorder = wavRecorderRef.current;
  const wavStreamPlayer = wavStreamPlayerRef.current;

  client.disconnect();
  await wavRecorder.end();
  await wavStreamPlayer.interrupt();

  // Clear timeout when disconnected manually
  if (timeoutRef.current) {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }

  // Ensure transcript is saved before proceeding to analysis
  await saveTranscriptToGoogleSheet(sellerEmail);

  // Analyze the transcript for meeting inference
  await analyzeTranscriptForMeeting();

  setIsConnected(false);
};

const analyzeTranscriptForMeeting = async () => {
  const transcript = conversationItems
    .map((item) => `${item.role}: ${item.formatted.transcript || ''}`)
    .join('\n');

  try {
    const response = await fetch('https://api.solaia.xyz/analyze-transcript', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        transcript: transcript,
        systemPrompt: `Summarize the conversation transcript into short bullet points detailing only the specific actions, results, or follow-ups discussed. Use the language of the conversation. Focus on concise action items:`,
      }),
    });

    if (!response.ok) {
      throw new Error(`API Error: ${response.statusText}`);
    }

    const data = await response.json();
    const actionSummaries = data.choices[0].message.content.replace(/\n/g, '<br>');

    console.log('Action Summaries:', actionSummaries);

    // Always display the "Book meeting" link
    const agreementHTML = `<a href="https://calendly.com/philipppuenjer" target="_blank">Book a meeting</a>`;
    setAgreements([actionSummaries, agreementHTML]);
  } catch (error) {
    console.error('Error analyzing transcript for agreements:', error);
    setAgreements([]);
  }
};

  
const generateAndSendSummary = async () => {
  const transcript = conversationItems
    .map((item) => `${item.role}: ${item.formatted.transcript || ''}`)
    .join('\n');

  setIsEmailSending(true);

  try {
    // Call backend API to generate summary
    const response = await fetch('https://api.solaia.xyz/generate-summary', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        transcript: transcript,
        systemPrompt: `Please summarize the following conversation in a detailed yet concise manner. Focus on the main topics discussed and any key outcomes. Use the language of the conversation for your output.`,
      }),
    });

    if (!response.ok) {
      throw new Error(`API Error: ${response.statusText}`);
    }

    const data = await response.json();
    const generatedSummary = data.choices?.[0]?.message?.content || 'No summary available.';
    setSummary(generatedSummary);

    // Save summary, user email, and seller email to Google Sheet
    const payload = {
      summary: generatedSummary,
      email: email, // user's email
      sellerEmail: sellerEmail, // seller's email fetched from state
      date: new Date().toISOString(),
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(payload));

    const sheetResponse = await fetch(
      'https://script.google.com/macros/s/AKfycbweTjgDWzTRXs_eDjlJAuiZ60EOYBqSv3CNeBAejS36TmBp-kWJdWSbOE3nFYU4D9dP/exec',
      {
        method: 'POST',
        body: formData,
      }
    );

    const result = await sheetResponse.text();
    const parsedResult = JSON.parse(result);

    if (parsedResult.status !== 'Success') {
      throw new Error(`Failed to save summary: ${parsedResult.message}`);
    }

    console.log('Summary and email saved:', parsedResult);

    // Show success message to the user
    alert('Summary and data saved successfully!');
    setError(null); // Clear any previous errors
  } catch (error) {
    console.error('Error generating or saving summary:', error);
    setError('Failed to send summary. Please try again.');
  } finally {
    setIsEmailSending(false);
  }
};
  
const DisclaimerBox: React.FC = () => {
  // State to manage the visibility of the disclaimer details
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '500px',
        margin: '16px auto',
        backgroundColor: '#FFF',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <h3
        style={{ fontSize: '1.25rem', fontWeight: 'bold', cursor: 'pointer' }}
        onClick={() => setIsOpen(!isOpen)}  // Toggle the visibility of the disclaimer details
      >
        Disclaimer
      </h3>
      {isOpen && (
        <div style={{ textAlign: 'center' }}>
          <p>Diese Webseite ist ein Prototyp und dient ausschließlich zu Demonstrations- und Entwicklungszwecken. Es handelt sich hierbei nicht um ein öffentliches Angebot. Inhalte und Funktionen können unvollständig oder simuliert sein und stellen keinen verbindlichen Leistungsumfang dar.</p>
          <p>Die Nutzung erfolgt ausschließlich im Rahmen von internen Tests und zur Präsentation von Konzepten. Eine dauerhafte Speicherung oder Verarbeitung personenbezogener Daten erfolgt nicht, es sei denn, dies ist für den Betrieb dieser Demonstrationsumgebung technisch erforderlich.</p>
          <p>Für Rückfragen schreiben Sie bitte eine E-Mail an <a href="mailto:contact@solaia.xyz">contact@solaia.xyz</a></p>
        </div>
      )}
    </div>
  );
};
  
return (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '100vh',
    backgroundColor: '#F3F4F6',
    padding: '16px'
  }}>
    <Logo logoUrl={logoUrl} />
<div
  style={{
    width: '100%',
    maxWidth: '500px',
    margin: '16px auto',
    backgroundColor: '#FFF',
    padding: '16px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  }}
>
  <h2 style={{ textAlign: 'center' }}>How can I help you?</h2>
    <Button
      onClick={toggleConnection}
      disabled={isLoading || !!error}
      buttonStyle={isConnected ? 'regular' : 'action'}
      label={isLoading ? 'Loading...' : isConnected ? 'Disconnect from AI Expert' : 'Talk to the AI Expert'}
    />
    <ConversationAnimation
      clientCanvasRef={clientCanvasRef}
      serverCanvasRef={serverCanvasRef}
    />
</div>
    {/* Agreements Display */}
    <AgreementsDisplay agreements={agreements} />

    <div
      style={{
        width: '100%',
        maxWidth: '500px', // Limit the container width
        margin: '16px auto', // Center the container horizontally
        backgroundColor: '#FFF',
        padding: '16px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center content inside the container
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Add a subtle shadow
      }}
    >
      <h2 style={{ textAlign: 'center' }}>Send Summary</h2>
      <p style={{ textAlign: 'center' }}>Send conversation summary to your e-mail inbox:</p>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (isLoading || !email) return;

          setIsLoading(true);
          await generateAndSendSummary();
          setIsLoading(false);
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', // Center the form fields and button
          gap: '12px', // Space between fields
          width: '100%', // Ensure form spans full container width
        }}
      >
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          style={{
            padding: '8px',
            border: '1px solid #CCC',
            borderRadius: '4px',
            fontSize: '1rem',
            width: '80%', // Set to 80% of the container width
            textAlign: 'center', // Center text inside the input field
          }}
        />
        <Button
          type="submit"
          disabled={isEmailSending || isTranscriptSaving || !email}
          buttonStyle="action"
          label={isEmailSending ? 'Sending...' : 'Send Summary'}
          style={{
            width: '80%', // Set button width to 80% of the container
            padding: '8px', // Add padding for better clickability
            textAlign: 'center', // Center-align button text
            display: 'flex', // Flexbox for proper centering
            justifyContent: 'center', // Horizontally center the text
            alignItems: 'center', // Vertically center the text
          }}
        />
      </form>
      </div>
      <DisclaimerBox />
    {error && <p style={{ marginTop: '16px', color: '#EF4444' }}>{error}</p>}
  </div>
);
};
